.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Add a higher z-index */
}

.popup-content {
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
  /* Add a higher z-index */
}

  
.btn-close {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btn-close:hover {
  color: rgba(255, 255, 255, 0.5); /* Change color on hover */
}

.bg-primary {
  background-color: var(--bs-primary) !important/*#007bff !important;*/ /* Override Bootstrap's default primary color */
}

.carousel-image {
  height: 300px; /* Set a fixed height for the carousel images */
  object-fit: cover; /* Maintain aspect ratio and cover the entire space */
}


@media (min-width: 576px) {
  .popup-content {
    width: 500px;
  }
}

@media (max-width: 576px) {
  .popup-content {
    width: 90%;
  }
}